@mixin contrast-dark-mode($global: false) {
  $root: &;
  $theme-name: 'contrast-dark';

  @if not $root {
    [data-color-mode='#{$theme-name}'] {
      @content;
    }
  } @else if $global {
    :global([data-color-mode='#{$theme-name}']) & {
      @content;
    }
  } @else {
    [data-color-mode='#{$theme-name}'] & {
      @content;
    }
  }
}
